
import { BusinessPageJobList } from "@/components/businesspage"

const FLUID_SEARCH_RESULT_CONFIG = {
  enabled: true,
  maxCols: 3,
  minColWidth: 270,
  maxColWidth: 450,
}

export default {
    name: "BusinessRoute",
    components: {
        BusinessPageJobList,
    },
    props: {
        fullWidth: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        FLUID_SEARCH_RESULT_CONFIG,
    }),
    computed: {
        jobs() {
            return this.$businessProvider.business.jobs
        },
    },
}
